import Vue from 'vue'
import VueRouter from 'vue-router'
import invoice_route from './modules/invoice_route'
import document_route from './modules/documents_route'
import location_route from "@/router/modules/location_route";
import shift_route from "@/router/modules/shifts_route";
import locum_route from '@/router/modules/locum_route'


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        ...invoice_route,
        ...location_route,
        ...document_route,
        ...shift_route

    ],
})

export default router
