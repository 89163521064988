export default [

    {
        path: '/documents',
        name: 'documents',
        component: () => import('@/views/documents/index.vue'),
        meta: {
            title: 'My Documents',
            // breadcrumb: [
            //
            //     {
            //         text: 'Invoice',
            //         active: true,
            //     },
            // ],
        },
    },
    // {
    //     path: '/invoice/view',
    //     name: 'invoice/views',
    //     component: () => import('@/views/invoice/view-invoice.vue'),
    //     meta: {
    //         // breadcrumb: [
    //         //
    //         //     {
    //         //         text: 'Invoice',
    //         //         active: true,
    //         //     },
    //         // ],
    //     },
    //
    // },
]


