import store from '../store/profile/index'
export default {
  init(router, store) {
    router.beforeEach(async (to, _, next) => {


      // const {currentUser} = store.getters
      // if (currentUser === null){
      //   await store.dispatch('autoLogin')
      // }

      //   if (!canNavigate(to)) {
      //     if (!isLoggedIn) return next({ name: 'auth-login' })
      //     return next({ name: 'misc-not-authorized' })
      //   }
      //
      // const { isLogedIn } = store.getters
      //
      // if (to.meta.redirectIfLoggedIn && isLogedIn) {
      //   next({
      //     path: '/shifts',
      //     replace: true,
      //   })
      // }
      //
      // if (!to.meta.noAuth && !isLogedIn) {
      //   next({
      //     path: '/shifts',
      //     replace: true,
      //   })
      // }


      return next()
    })
  },
  computed: {



  },
}
