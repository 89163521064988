export default {
  apiUrl: `${process.env.VUE_APP_API_URL}`,
  mpAppUrl: `${process.env.VUE_APP_MP_APP_URL}`,
};
export const imageBaseApiUrl = `${process.env.VUE_APP_API_URL}api/mp`;
export const apiUrl = `${process.env.VUE_APP_API_URL}api`;
export const loginAppUrl = `${process.env.VUE_APP_PROFILE_URL}`;

export const statusWithColors = [
  { text: 'approved', variant: 'light-primary' },
  { text: 'declined', variant: 'light-danger' },
  { text: 'under review', variant: 'light-warning' },
  { text: 'not mandatory', variant: 'light-secondary' },
];
