export default [
    {
        path: '/shifts',
        name: 'shifts',
        component: () => import('@/views/shifts/index.vue'),
        meta: {
            title: 'My shifts',
            // breadcrumb: [
            //     {
            //         text: 'Home',
            //         active: true,
            //     },
            // ],
        },
    },
    {
        path: '/shifts/vacant',
        name: 'vacant-shifts',
        component: () => import('@/views/shifts/vacant-shift'),
        meta: {
            title: 'My shifts',
            // breadcrumb: [
            //     {
            //         text: 'Home',
            //         active: true,
            //     },
            // ],
        },
    },
    // {
    //     path: '/invoice',
    //     name: 'invoice',
    //     component: () => import('@/views/invoice/index.vue'),
    //     meta: {
    //         // breadcrumb: [
    //         //
    //         //     {
    //         //         text: 'Invoice',
    //         //         active: true,
    //         //     },
    //         // ],
    //     },
    //
    // },
    // {
    //     path: '/invoice/view',
    //     name: 'invoice/views',
    //     component: () => import('@/views/invoice/view-invoice.vue'),
    //     meta: {
    //         // breadcrumb: [
    //         //
    //         //     {
    //         //         text: 'Invoice',
    //         //         active: true,
    //         //     },
    //         // ],
    //     },
    //
    // },
]


