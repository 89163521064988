export default [

    {
        path: '/invoice',
        name: 'invoice',
        component: () => import('@/views/invoice/index.vue'),
        meta: {
            title: 'My Invoice',
            // breadcrumb: [
            //
            //     {
            //         text: 'Invoice',
            //         active: true,
            //     },
            // ],
        },

    },
    {
        path: '/invoice/view/:id',
        name: 'invoice/views',
        component: () => import('@/views/invoice/view-invoice.vue'),
        meta: {
            title: 'My Invoice',
            // breadcrumb: [
            //
            //     {
            //         text: 'Invoice',
            //         active: true,
            //     },
            // ],
        },

    },
]


