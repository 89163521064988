import moment from "moment";
import {statusWithColors} from '@/constants/config'

export default {
    methods: {
        getPrice (price) {
            return `£ ${parseFloat(price).toFixed(2)}`
        },
        totalShiftCost (start, end, rate) {

            if (!rate) return

            var date = '2017-03-13'
            start = moment( start)
            end = moment( end)
            const formatted_price = ((moment(end).diff(moment(start), 'minutes')) / 60) * rate
            if (isNaN(formatted_price)) return 0
            return formatted_price.toFixed(2)
        },
        shiftTimeDeferent (start, end) {
            start = moment( start)
            end = moment( end)
            const formatted_hours = ((moment(end).diff(moment(start), 'minutes')) / 60)

            return formatted_hours
        },
        makeUpperCase (value) {
            return  _.startCase(_.toUpper(value))
        },
        getStatusWithColor (state) {
            return _.find(statusWithColors, {text: state})
        },
        firstLetterUpperCase (value) {
            if (value === null) return 'N/A'
            return _.startCase(_.toLower(value))
        },

    }
}
